import React from "react";
import "./Navbar.scss";
import logo from "./../../img/99minutos_logo.svg";

const Navbar = () => {
  return (
    <div className="navbar">
      <img src={logo} alt="logo" className="navbar__image" />
    </div>
  );
};

export default Navbar;
