import { createContext, useReducer } from "react";
import rateReducer from "./RateReducer";
import types from "./../types";
import axios from "axios";
import urls from "./../../requests/constants";

const initialState = {
  loading: false,
  error: null,
  rateInfo: { isRateSent: false, rate: 0 },
  feedbackInfo: {
    image: "",
    title: { green: "", black: "" },
    text: { gray: "", black: "" },
  },
};

export const RateContext = createContext(initialState);

export const RateContextProvider = ({ children }) => {
  const [RateState, dispatch] = useReducer(rateReducer, initialState);

  const rateOrder = async (rating, id) => {
    dispatch({ type: types.RATE_ORDER });
    try {
      const response = await axios({
        url: `${urls.ratingOrders}${id}`,
        method: "patch",
        data: {
          rating: rating.value,
          commentrating: rating.comment,
        },
      });
      if (response.status === 204) {
        dispatch({ type: types.RATE_ORDER_SUCCESS });
        setTimeout(() => {
          window.location.href = "https://99minutos.com";
        }, 2000);
      }
    } catch (error) {
      dispatch({
        type: types.RATE_ORDER_ERROR,
        payload: error.message ? error.message : "Server Error",
      });
    }
  };

  const setRate = (value) => {
    dispatch({ type: types.SET_RATE, payload: value });
  };

  return (
    <RateContext.Provider value={{ rateState: RateState, setRate, rateOrder }}>
      {children}
    </RateContext.Provider>
  );
};
