import types from "./../types";

import graphicScoreOneStar from "./../../img/graphic_score_1star.svg";
import graphicScoreThreeStar from "./../../img/graphic_score_3star.svg";
import graphicScoreFiveStar from "./../../img/graphic_score_5star.svg";

const setFeedbackInfo = (value) => {
  let feedbackInfo;
  if (value === 1) {
    feedbackInfo = {
      image: graphicScoreOneStar,
      title: {
        green: "Nos calificaste con 1 estrella,",
        black: "lamentamos tu experiencia",
      },
      text: {
        gray: "Siempre buscamos mejorar,",
        black: "¿nos ayudarías con tus comentarios?",
      },
    };
  } else if (value === 2) {
    feedbackInfo = {
      image: graphicScoreOneStar,
      title: {
        green: "Nos calificaste con 2 estrellas,",
        black: "lamentamos tu experiencia",
      },
      text: {
        gray: "Siempre buscamos mejorar,",
        black: "¿nos ayudarías con tus comentarios?",
      },
    };
  } else if (value === 3) {
    feedbackInfo = {
      image: graphicScoreThreeStar,
      title: {
        green: "Nos calificaste con 3 estrellas,",
        black: "siempre podemos mejorar",
      },
      text: {
        gray: "Entendimos que tuviste una buena experiencia,",
        black: "¿cómo podemos mejorar?",
      },
    };
  } else if (value === 4) {
    feedbackInfo = {
      image: graphicScoreThreeStar,
      title: {
        green: "Nos calificaste con 4 estrellas,",
        black: "siempre podemos mejorar",
      },
      text: {
        gray: "Entendimos que tuviste una buena experiencia,",
        black: "¿cómo podemos mejorar?",
      },
    };
  } else if (value === 5) {
    feedbackInfo = {
      image: graphicScoreFiveStar,
      title: {
        green: "¡Nos calificaste con 5 estrellas!",
        black: "Estamos felices por tu calificación",
      },
      text: {
        gray: "Aun así siempre podemos mejorar,",
        black: "¿nos ayudas?",
      },
    };
  }
  return feedbackInfo;
};

export default (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_RATE:
      return {
        rateInfo: { isRateSent: true, value: payload },
        feedbackInfo: setFeedbackInfo(payload),
      };
    case types.RATE_ORDER:
      return {
        loading: true,
        error: null,
        rateInfo: { isRateSent: false, rate: 0 },
        feedbackInfo: {
          image: "",
          title: { green: "", black: "" },
          text: { gray: "", black: "" },
        },
      };
    case types.RATE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case types.RATE_ORDER_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
