import React, { useState, useEffect } from "react";
import "./Star.scss";
import star from "./../../img/icon_star.svg";
import greenStar from "./../../img/icon_stargreen.svg";

const Star = ({ rate, onClickHandler, value }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    if (rate >= value) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  }, [rate]);

  return (
    <img
      src={enabled ? `${greenStar}` : `${star}`}
      alt="star"
      className="star"
      onClick={() => onClickHandler(value)}
    />
  );
};

export default Star;
