import React, { useState, useContext } from "react";
import "./RateDelivery.scss";
import Star from "./../Star/Star";
import Button from "./../Button/Button";
import graphicScore from "./../../img/graphic_score.svg";
import { RateContext } from "./../../context/Rate/RateContext";
import Alert from "@mui/material/Alert";


const RateDelivery = () => {
  const [rateValue, setRateValue] = useState();
  const { setRate } = useContext(RateContext);
  const [rating, setRating] = useState(window.sessionStorage.getItem("isRated"));

  const setRateHandler = (value) => setRateValue(value);

  const rateHandler = () => {
    if (rateValue) {
      setRate(rateValue);
      window.sessionStorage.setItem("isRated", rateValue);
    }
  }

  return (
    <div className="rateDelivery">
      {rating ? (
      <>
            <div className="rateDelivery__image">
            <img src={graphicScore} alt="graphic_score" />
          </div>
          <Alert variant="filled" sx={{ backgroundColor: "#85C440", marginTop:"2em" }}>
            ¡Gracias por tu calificación, seguiremos mejorando para darte un
            mejor servicio!
          </Alert>
          </>
      ) : (
        <>
          <div className="rateDelivery__image">
            <img src={graphicScore} alt="graphic_score" />
          </div>
          <div className="rateDelivery__title">
            <h4>
              ¿Cómo <span>calificarías</span> nuestra entrega?
            </h4>
          </div>
          <div className="rateDelivery__stars">
            <Star rate={rateValue} value={1} onClickHandler={setRateHandler} />
            <Star rate={rateValue} value={2} onClickHandler={setRateHandler} />
            <Star rate={rateValue} value={3} onClickHandler={setRateHandler} />
            <Star rate={rateValue} value={4} onClickHandler={setRateHandler} />
            <Star rate={rateValue} value={5} onClickHandler={setRateHandler} />
          </div>
          <div className="rateDelivery__button">
            <Button text="Calificar" onClickHandler={rateHandler} />
          </div>
        </>
      )}
    </div>
  );
};

export default RateDelivery;
