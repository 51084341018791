import { useReducer, createContext } from "react";
import shippingReducer from "./ShippingReducer";
import axios from "axios";
import types from "./../types";
import urls from "./../../requests/constants";

const initialState = {
  loading: false,
  error: null,
  details: null,
};

export const ShippingContext = createContext(initialState);

export const ShippingContextProvider = ({ children }) => {
  const [ShippingState, dispatch] = useReducer(shippingReducer, initialState);

  const getShippingDetails = async (id) => {
    dispatch({ type: types.GET_SHIPPING_DETAILS });
    try {
      const response = await axios({
        url: `${urls.scoreOrder}${id}`,
        method: "get",
      });

      const details = {
        order: response.data.data.counter || "",
        vehicle: response.data.data.vehicle || "",
        deliveryMan: response.data.data.driversuser || "",
        mode: response.data.data.deliverytype || "",
        receiver: response.data.data.received || "",
      };

      dispatch({ type: types.GET_SHIPPING_DETAILS_SUCCESS, payload: details });
    } catch (error) {
      // eslint-disable-next-line no-restricted-globals
      location.href = "https://99minutos.com";
      dispatch({
        type: types.GET_SHIPPING_DETAILS_ERROR,
        payload: error.message ? error.message : "Server Error",
      });
    }
  };

  return (
    <ShippingContext.Provider
      value={{ shippingState: ShippingState, getShippingDetails }}
    >
      {children}
    </ShippingContext.Provider>
  );
};
