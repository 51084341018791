import types from "./../types";

export default (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_SHIPPING_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case types.GET_SHIPPING_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        details: payload,
        error: null,
      };
    case types.GET_SHIPPING_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        details: null,
      };
    default:
      return state;
  }
};
