import React, { useContext, useEffect} from "react";
import "./Content.scss";
import ShippingInfo from "./../ShippingInfo/ShippingInfo";
import RateDelivery from "./../RateDelivery/RateDelivery";
import { RateContext } from "./../../context/Rate/RateContext";
import { ShippingContext } from "./../../context/Shipping/ShippingContext";
import FeedbackForm from "./../FeedbackForm/FeedbackForm";
import truckGif from "./../../img/truck.gif";



const Content = () => {
  const { rateState } = useContext(RateContext);
  const { getShippingDetails } = useContext(ShippingContext);

  const url = window.location.href;
  const id = url.split('/')[url.split('/').length -1];

  useEffect(() => {
    getShippingDetails(id);
  }, [id]);

  return (
    <div className="content">
      <ShippingInfo />

      {!rateState.rateInfo.isRateSent && !rateState.loading && <RateDelivery />}

      {rateState.loading && <img className="content__image" src={truckGif} />} 

      {rateState.rateInfo.isRateSent && (
        <FeedbackForm
          image={rateState.feedbackInfo.image}
          title={rateState.feedbackInfo.title}
          text={rateState.feedbackInfo.text}
        />
      )}
    
    </div>
  );
};

export default Content;
