import React from "react";
import "./ShippingInfo.scss";
import ShippingDetails from "./../ShippingDetails/ShippingDetails";

const ShippingInfo = () => {
  return (
    <div className="shippingInfo">
      <div className="shippingInfo__content">
        <h4>¡Gracias por usar 99 minutos!</h4>
        <ShippingDetails />
      </div>
    </div>
  );
};

export default ShippingInfo;
