import React from "react";
import "./ShippingDetail.scss";

const ShippingDetail = ({ icon, label, content }) => {
  return (
    <div className="shippingDetail">
      <div className="shippingDetail__title">
        <img src={icon} alt={icon} />
        <span>{label}</span>
      </div>
      <div className="shippingDetail__content">
        <p>{content}</p>
      </div>
    </div>
  );
};

export default ShippingDetail;
