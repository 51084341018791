import React, { useContext } from "react";
import "./ShippingDetails.scss";
import { ShippingContext } from "./../../context/Shipping/ShippingContext";
import greenArrow from "./../../img/arrow_green.svg";
import ShippingDetail from "./../ShippingDetail/ShippingDetail";
import icon_orden from "./../../img/icon_orden.svg";
import icon_vehiculo from "./../../img/icon_vehiculo.svg";
import icon_veloz from "./../../img/icon_veloz.svg";
import icon_modalidad from "./../../img/icon_modalidad.svg";
import icon_recibio from "./../../img/icon_recibio.svg";
import truckGif from "./../../img/truck.gif";

const ShippingDetails = () => {
  const { shippingState } = useContext(ShippingContext);

  return (
    <div className="shippingDetails">
      <div className="shippingDetails__title">
        <img src={greenArrow} alt="greenArrow" />
        <h5>Detalles de tu envío</h5>
      </div>
      {shippingState.loading && (
        <img className="shippingDetails__gif" src={truckGif} />
      )}
      {!shippingState.loading && shippingState.error && (
        <p>
          No hemos encontrado la orden que buscas calificar, verifica la clave de rastreo.

          {shippingState.error}
          
          </p>
      )}
      {!shippingState.loading && shippingState.details && (
        <>
          <ShippingDetail
            icon={icon_orden}
            label="Orden"
            content={shippingState.details.order}
          />
          <ShippingDetail
            icon={icon_veloz}
            label="Servicio"
            content={shippingState.details.deliveryMan}
          />
          <ShippingDetail
            icon={icon_recibio}
            label="Recibió"
            content={shippingState.details.receiver}
          />
        </>
      )}
    </div>
  );
};

export default ShippingDetails;
