import React, { useState, useContext } from "react";
import { RateContext } from "./../../context/Rate/RateContext";
import Button from "./../Button/Button";
import "./FeedbackForm.scss";
import TextField from "@mui/material/TextField";


const FeedbackForm = ({ image, title, text }) => {
  const [value, setValue] = useState("");
  const { rateState, rateOrder } = useContext(RateContext);

  const url = window.location.href;
  const id = url.split("/")[url.split("/").length - 1];

  const buttonAlert = () => {
    const rating = { comment: value ?? "", value: rateState.rateInfo.value };
    rateOrder(rating, id);
  
  };

  return (
    <div className="feedBackForm">
      <div className="feedBackForm__image">
        <img src={image} alt="feedbackImage" />
      </div>
      <div className="feedBackForm__title">
        <h4>
          <span>{title.green}</span> {title.black}
        </h4>
      </div>
      <div className="feedBackForm__text">
        <p>
          <span>{text.gray}</span> {text.black}
        </p>
      </div>
      <div className="feedBackForm__form">
          <TextField
            value={value}
            label="Escribe tu comentario"
            onChange={(e) => setValue(e.target.value)}
          />
          <div className="feedBackForm__form__buttons">
            <Button
              transparent={true}
              text="Saltar"
              type="button"
              onClickHandler={buttonAlert}
            />
            <Button
              transparent={false}
              text="Enviar"
              type="button"
              onClickHandler={buttonAlert}
            />
          </div>
        </div>
    </div>
  );
};

export default FeedbackForm;
