import React from "react";
import "./Button.scss";

const Button = ({ text, customStyles, transparent, onClickHandler, type }) => {
  return (
    <button
      className={`button ${transparent ? `button--transparent` : ""}`}
      style={customStyles}
      onClick={onClickHandler}
      type={type}
    >
      {text}
    </button>
  );
};

export default Button;
