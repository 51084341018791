import "./sass/base.scss";
import Navbar from "./components/Navbar/Navbar";
import Content from "./components/Content/Content";
import { RateContextProvider } from "./context/Rate/RateContext";
import { ShippingContextProvider } from "./context/Shipping/ShippingContext";

function App() {
  return (
    <div className="App">
      <RateContextProvider>
        <ShippingContextProvider>
          <Navbar />
          <Content />
        </ShippingContextProvider>
      </RateContextProvider>
    </div>
  );
}

export default App;
